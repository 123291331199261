import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "author": "Hops Wong",
  "date": "2008-03-05 UTC -8",
  "length": 13,
  "location": "Taibei, Taiwan",
  "path": "/formosan-dogs/",
  "tags": ["dogs", "personal"],
  "title": "Formosan Dogs are Formidable"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The Taiwan Dog (Chinese: 台灣犬) is a breed of small or medium dog indigenous to Taiwan. These dogs are also known as the Formosan Mountain Dog, Taiwanese Canis, Taiwanese Native Dog (台灣土狗) or Takasago Dog (高砂犬). They are well-adapted to the uneven and thickly forested terrain of Taiwan, having become a semi-wild breed prior to the arrival of several colonial reigns and foreign powers. Notwithstanding these adaptations, Formosans retained the potential to be trained and are now used as hunting dogs, guard dogs, stunt dogs, rescue dogs, or simply as companions.`}</p>
    <p>{`Formosans are classified into one medium type and two small types. However, now the pureblooded Formosan Mountain Dog is edging closer to extinction, due to limited conservation efforts of the Taiwanese and their government.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAA//aAAwDAQACEAMQAAABcnOQsnXNJf/EABoQAAIDAQEAAAAAAAAAAAAAAAECAxESACL/2gAIAQEAAQUCkTUWw6qCFLeIjRvv/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BSf/EABYRAAMAAAAAAAAAAAAAAAAAAAECEP/aAAgBAgEBPwFRP//EABsQAQACAgMAAAAAAAAAAAAAAAEAEQISEDFx/9oACAEBAAY/Asi5sotdQ1aj5z//xAAbEAEAAwADAQAAAAAAAAAAAAABABExIUFRYf/aAAgBAQABPyEHdGy5M68MgditK7i0/UT1MjP/2gAMAwEAAgADAAAAEP8A7//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAwEBPxAhLC//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAECAQE/EADhbf/EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQVFhcbH/2gAIAQEAAT8QVEZTomT5LKohKxwPeruGpLQLC3C1NhfsivkFj5KjKIBjU//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Formosan Mountain Dog",
          "title": "Formosan Mountain Dog",
          "src": "/static/bab7eac111b98e81ff688a36fd8b951e/8e1fc/hops.jpg",
          "srcSet": ["/static/bab7eac111b98e81ff688a36fd8b951e/863e1/hops.jpg 225w", "/static/bab7eac111b98e81ff688a36fd8b951e/20e5d/hops.jpg 450w", "/static/bab7eac111b98e81ff688a36fd8b951e/8e1fc/hops.jpg 900w", "/static/bab7eac111b98e81ff688a36fd8b951e/b768e/hops.jpg 1350w", "/static/bab7eac111b98e81ff688a36fd8b951e/d9c39/hops.jpg 1800w", "/static/bab7eac111b98e81ff688a36fd8b951e/e1596/hops.jpg 2048w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      